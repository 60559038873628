<template>
    <div class="auth-wrapper auth-v1"
         style="padding-bottom:0px;padding-top:0px;">
        <div class="auth-inner main-container-width">
            <v-card>
                <v-row>
                    <v-col cols="12"
                           sm="12"
                           md="12"
                           lg="12"
                           class="mx-auto">


                        <v-card>
                            <v-row class="mx-lg-5 mx-2">
                                <v-col sm="12"
                                       md="12"
                                       lg="12">
                                    <v-card flat
                                            class="full-min-height">
                                        <v-card-text class="pb-0">

                                        </v-card-text>

                                        <v-stepper v-model="step"
                                                   class="custom"
                                                   style="background: transparent;">
                                            <v-stepper-items>


                                                <v-stepper-content step="1">
                                                    <UpdatePasswordStep1 @submit="nextStep($event)"
                                                                         v-if="step == '1'"></UpdatePasswordStep1>
                                                    <!-- Set Password -->
                                                </v-stepper-content>
                                                <v-stepper-content step="2">

                                                    <v-row class="pa-16 success-message">
                                                        <v-col lg="12"
                                                               class="text-center">
                                                            <div class="circle">
                                                                <img src="@/assets/images/Success.png"
                                                                     alt=""
                                                                     width="70px">
                                                            </div>


                                                            <h2 style="font-size: 20px;">
                                                                Great! You have updated your password. You are ready to go.
                                                            </h2>


                                                            <v-row class="pa-12">
                                                                <v-col lg="12"
                                                                       class="text-center">
                                                                    <v-btn class=""
                                                                           style="width: 100%;"
                                                                           color="primary"
                                                                           type="button">
                                                                        {{ $t('Done') }}
                                                                    </v-btn>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-stepper-content>

                                            </v-stepper-items>
                                        </v-stepper>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>

        </div>
    </div>
</template>

<script>
import themeConfig from '@themeConfig'


import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import UpdatePasswordStep1 from './UpdatePasswordStep1.vue';
import router from '@/router'
import store from '@/store'


export default {
    components: { AppBarI18n, AppBarThemeSwitcher, UpdatePasswordStep1 },
    data: () => ({
        step: 1,
        finished: false,
    }),
    mounted()
    {
        window.scrollTo(0, 0);
    },
    methods: {
        nextStep()
        {
            this.step++;
            window.scrollTo(0, 0);
        },
        backStep()
        {
            this.step--;
            window.scrollTo(0, 0);
        },
        finishedEnrollment()
        {
            this.finished = true;
            window.scrollTo(0, 0);
        },
        backToSignIn()
        {
            router.push('/login')
        }
    },
    setup()
    {
        return {
            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

.confirmation {
    width: 40vw;
    margin: auto;
}

@media screen and (max-width: 699px) {
    .text-black {
        font-size: 17px !important;
    }

    .text-color-black {
        font-size: 14px !important;
    }

    .confirmation {
        width: 80vw;
        margin: auto;
    }


}

@media screen and (max-width: 400px) {
    .v-stepper__content {
        padding: 24px 0px !important;
    }
}

.success-message {
    width: 50%;
    margin: 0 auto;
}

.circle {
    background: #E2F1FD;
    width: 118px;
    height: 115px;
    margin: 0 auto;
    padding: 31px 0px;
    border-radius: 70px;

    img {
        width: 55px;
    }
}

@media screen and (max-width: 1000px) {
    .success-message {
        width: 100%;
        margin: 0 auto;
    }
}
</style>
