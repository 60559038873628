import axios from './axiosInit';
import store from '@/store';
import sharedServices from './shared-services';
// const updatePassword = async (data) =>
// {
    
//     let body = {
        
//         requestInfo: {
//             requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
//             requestDate: store.get('requestKeys/requestDate'),
//             identityKey: store.get('requestKeys/identityKey'),
//             channelId: store.get('requestKeys/channelId'),
//             channelKey: store.get('requestKeys/channelKey'),
//             identitySeed: store.get('requestKeys/identitySeed'),
//             userName: store.get('requestKeys/userName'),
//         },
//         oldPasswordHash: data.password,
//         newPasswordHash: data.confirmPassword,
//     }
//     const auth = {
//         headers: {
//           Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
//         }
//       }
//     try
//     {
//         store.dispatch('loader/show');
//         const result = await axios.post('https://online.americanexpress.com.se/User/passwordchange', body,auth)
//         store.dispatch('loader/hide');
//         return result
//     } catch (e)
//     {
//         // console.log(e)
//     }
// }
let username=store.get('requestKeys/userName')
const updatePassword = async (data) =>
{
    
    try
    {
        store.dispatch('loader/show');
        
        return sharedServices.getToken('entity', username).then(async entityRes =>
        {
            let payload = {
                requestInfo: {
                    channelKey: entityRes.data.results.channelKey,
                    identityKey: entityRes.data.results.identityKey,
                    requestDate: entityRes.data.results.requestDate,
                    requestUID: (Math.floor(1000 + Math.random() * 9000)).toString(),
                    channelId: store.get('requestKeys/channelId'),
                    userName: store.get('requestKeys/userName'),
                    identitySeed: sharedServices.getIdSeed()
                },
                oldPasswordHash: data.password,
        newPasswordHash: data.confirmPassword,
                
            };

            const auth = {
                headers: {
                  Authorization: 'Bearer ' + store.get('requestKeys/accessToken')
                }
              }

            const result = await axios.post('hpsbridge/se/User/passwordchange', payload, auth)
            store.dispatch('loader/hide');
            return result
        })
    } catch (e)
    {
        // console.log(e)
    }
}


export default {
    updatePassword
}
